import * as Sentry from "@sentry/browser";
import * as React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import { ThemeProvider } from "styled-components/macro";
import GlobalStyle from "../GlobalStyle";
import { RootState, ThunkDispatch } from "../core/store";
import { getBootstrapped } from "../core/store/bootstrap/reducers";
import { bootstrap } from "../core/store/bootstrap/thunks";
import { getServerError } from "../core/store/global/reducers";
import { IServerError } from "../core/store/global/types";
import "../fonts.css";
import i18n from "../i18n";
import theme from "../theme";
import { isRTL } from "../utils/locale";
import { getIsWebView } from "../utils/webView";
import ElementDialog from "./ElementDialog";
import Footer from "./Footer";
import GameHeader from "./GameHeader";
import PageAd from "./PageAd";
import { Main, Wrapper } from "./Layout";
import Routes from "./Routes";
import ServerError from "./ServerError";
import AutoJoinDialog from "./leagues/AutoJoinDialog";

interface IProps {
  bootstrapped: boolean;
  serverError: IServerError | null;
}

export const Loading: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Main>
        <Box mx={2}>{t("loading", "Loading")} ...</Box>
      </Main>
    </Wrapper>
  );
};

const App: React.FC<IProps> = ({ bootstrapped, serverError }) => {
  return (
    <React.StrictMode>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <React.Suspense fallback={<div />}>
          <Helmet>
            <html lang={i18n.language} dir={isRTL() ? "rtl" : "ltr"} />
            <title>{i18n.t("app.gameName", "RSL Fantasy")}</title>
            <meta
              name="description"
              content={i18n.t(
                "app.description1n",
                "The new RSL Fantasy game for the 2023/24 season."
              )}
            />
          </Helmet>
          <div className="ism">
            <GameHeader />
            <PageAd />
            {serverError ? (
              <ServerError error={serverError} />
            ) : bootstrapped ? (
              <Routes />
            ) : (
              <Loading />
            )}
            {!getIsWebView() && <Footer />}
            <AutoJoinDialog />
            <ElementDialog />
          </div>
        </React.Suspense>
      </ThemeProvider>
    </React.StrictMode>
  );
};

interface IContainerPropsFromState {
  bootstrapped: boolean;
  serverError: IServerError | null;
}

interface IState {
  eventId: string | null;
  error: Error | null;
}

interface IContainerPropsFromDispatch {
  bootstrapApp: () => Promise<void>;
}
type ContainerProps = IContainerPropsFromState & IContainerPropsFromDispatch;
class AppContainer extends React.Component<ContainerProps, IState> {
  public state: IState = { error: null, eventId: null };

  public componentDidMount() {
    this.props.bootstrapApp();
  }

  public componentDidCatch(error: Error | null, errorInfo: {}) {
    this.setState({ error });
    if (process.env.REACT_APP_SENTRY_ENVIRONMENT) {
      Sentry.withScope((scope) => {
        scope.setExtras(errorInfo);
        const eventId = Sentry.captureException(error);
        this.setState({ eventId });
      });
    }
  }

  public render() {
    // JS Error
    if (this.state.error) {
      // Can't reliably use any of our components, eg Button. This should
      //probably be in Norwegian can't use react to do it though ...
      return (
        <>
          <h2>Error</h2>
          <p>We're sorry - something's gone wrong.</p>
          {process.env.REACT_APP_SENTRY_ENVIRONMENT && (
            <>
              <p>
                Our team has been notified, but click here to fill out a report.
              </p>
              <button
                onClick={() =>
                  Sentry.showReportDialog({
                    eventId: this.state.eventId as string,
                  })
                }
              >
                Report feedback
              </button>
            </>
          )}
        </>
      );
    }
    // All OK - Render App
    return (
      <App
        bootstrapped={this.props.bootstrapped}
        serverError={this.props.serverError}
      />
    );
  }
}

const mapStateToProps = (state: RootState): IContainerPropsFromState => ({
  bootstrapped: getBootstrapped(state),
  serverError: getServerError(state),
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch
): IContainerPropsFromDispatch => ({
  bootstrapApp: () => dispatch(bootstrap()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
